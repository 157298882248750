import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import {
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { auth, firestore, updateUser } from '../firebase/firebase';
import { format } from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReviewModal from './ReviewModal';
import ClientInfoModal from './ClientInfoModal';
import BookReviewModal from './BookReviewModal';
import ShipModal from './ShipModal';
import PrintModal from './PrintModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { PRODUCTION_STEPS } from '../utils/data';
import Dropdown from 'react-bootstrap/Dropdown';
import SendToPrintModal from './SendToPrintModal';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { SKIP_ANSWER_VALUE, SKIPPED_ANSWER_VALUE } from '../constants/common';
import ErrorToast from './ErrorToast';
import { useUserData } from '../hooks/useUserData';
import { useBookData } from '../hooks/useBookData';
import { IoWarning } from 'react-icons/io5';
import ConfirmModal from './ConfirmModal';
import { useCollection } from 'react-firebase-hooks/firestore';

const UsersTable = () => {
  const [reviewModal, setReviewModal] = useState(false);
  const [clientInfoModal, setClientInfoModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [shipModal, setShipModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [sendToPrintModal, setSendToPrintModal] = useState(false);
  const [selectedUserUid, setSelectedUserUid] = useState(null);
  const [usersLoading, setUsersLoading] = useState(false);
  const [startChapter, setChapterStart] = useState(1);
  const [endChapter, setChapterEnd] = useState(50);
  const [searchUser, setSearchUser] = useState(null);
  const [error, setError] = useState(null);
  const [model, setModel] = useState('gpt-3.5-turbo');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filter, setFilter] = useState(null);
  const [usersQuery, setUsersQuery] = useState(null);

  const [snapshot, snapshotLoading] = useCollection(usersQuery);
  const { i18n, t } = useTranslation();
  const [authUser] = useAuthState(auth);

  const applyFilter = (filterArg) => {
    setSelectedUserUid(null);
    setUsersQuery(query(collection(firestore, 'users'), filterArg));
  };

  const filtersData = [
    {
      label: 'Generate',
      value: 'generate_book',
    },
    {
      label: 'Review',
      value: 'review_book',
    },
    {
      label: 'Ready for print',
      value: 'ready_for_print_book',
    },
    {
      label: 'Print',
      value: 'print_book',
    },
    {
      label: 'Ship',
      value: 'ship_book',
    },
  ];

  const users = useMemo(() => {
    if (!snapshot) return [];

    try {
      setUsersLoading(true);
      setError(null);
      const usersForTable = [];

      snapshot?.forEach((doc) => {
        const data = doc.data();

        usersForTable.push({
          ...data,
          uid: doc.id,
          createdAtDate: data?.created_at
            ? format(data?.created_at * 1000, 'yyy MM dd')
            : null,
          lastVisitDate: data?.last_visit_date
            ? format(data?.last_visit_date * 1000, 'yyy MM dd')
            : null,
          generateBookDate: data?.generate_book
            ? format(data?.generate_book * 1000, 'yyy MM dd')
            : null,
          reviewBookDate: data?.review_book
            ? format(data?.review_book * 1000, 'yyy MM dd')
            : null,
          readyForPrintBookDate: data?.ready_for_print_book
            ? format(data?.ready_for_print_book * 1000, 'yyy MM dd')
            : null,
          printBookDate: data?.print_book
            ? format(data?.print_book * 1000, 'yyy MM dd')
            : null,
          shipBookDate: data?.ship_book
            ? format(data?.ship_book * 1000, 'yyy MM dd')
            : null,
        });
      });

      return filter
        ? [...usersForTable]?.filter((user) => {
            const currentProductionStepIndex = PRODUCTION_STEPS.indexOf(filter);
            if (!user?.[PRODUCTION_STEPS[currentProductionStepIndex + 1]]) {
              return user;
            }
            return false;
          })
        : [...usersForTable];
    } catch (e) {
      setError('Error fetching data. Please try again.');
      console.error(e);
    } finally {
      setUsersLoading(false);
    }
  }, [authUser, snapshot, filter]);

  const dataLoading = useMemo(() => {
    // TODO: check why previous user value is returned in-between renders when
    //  filter is applied
    const previousUserRendering = filter && snapshot?.size === 1;

    return previousUserRendering || snapshotLoading || usersLoading;
  }, [filter, snapshot, snapshotLoading, usersLoading]);

  const selectedUser = useMemo(() => {
    if (!selectedUserUid) return {};

    return users.find((u) => u.uid === selectedUserUid) || {};
  }, [users, selectedUserUid]);

  const activeUser = useMemo(() => {
    return Object.keys(selectedUser).length ? selectedUser : authUser;
  }, [authUser, selectedUser]);

  const { emailRepliesLoading, userQuestionsWithAnswers, userData } =
    useUserData(activeUser);
  const {
    generateChapters,
    constructBookData,
    loading,
    data: bookData,
  } = useBookData(
    activeUser,
    () => setReviewModal(true),
    startChapter,
    endChapter,
    model,
    selectedUser?.language || i18n.resolvedLanguage
  );

  const handleSaveGeneratedAnswers = async () => {
    constructBookData();
    const generatedData = bookData
      .map((i) => ({
        questionId: Number(i?.questionId),
        text: i?.answer || SKIP_ANSWER_VALUE,
        verified: false,
      }))
      ?.reduce((acc, curr) => {
        acc[curr?.questionId] = curr;
        return acc;
      }, {});

    await updateUser({
      uid: selectedUser?.uid,
      data: {
        book_qa_data: {
          ...(userData.book_qa_data || {}),
          ...generatedData,
        },
      },
      email: selectedUser?.email,
    });

    setReviewModal(false);
  };

  const handleSetStatus = async (status) => {
    await updateUser({
      uid: selectedUser?.uid,
      data: {
        [status]: Math.round(Date.now() / 1000),
      },
      email: selectedUser?.email,
    });
    setSelectedUserUid(null);
  };

  const handleRemoveStatusClick = (status) => {
    setSelectedStatus(status);
    setShowConfirmationModal(true);
  };

  const removeSelectedStatus = async () => {
    const updatedData = {
      [selectedStatus]: null,
    };

    if (selectedStatus === 'ready_for_print_book') {
      const oldBookQaData = selectedUser.book_qa_data;
      const newBookQaData = {};

      for (const key in oldBookQaData) {
        if (oldBookQaData.hasOwnProperty(key)) {
          newBookQaData[key] = {
            ...oldBookQaData[key],
            verified: false,
          };
        }
      }

      updatedData.cover = null;
      updatedData.cover_photo = null;
      updatedData.shipping_address = null;
      updatedData.book_qa_data = newBookQaData;
    }

    await updateUser({
      uid: selectedUser?.uid,
      data: updatedData,
      email: selectedUser?.email,
    });
    setShowConfirmationModal(false);
    setSelectedUserUid(null);
  };

  const deleteUser = async () => {
    const userDoc = doc(firestore, 'users', selectedUserUid);
    await deleteDoc(userDoc);
    setDeleteModal(false);
    setSelectedUserUid(null);
  };

  const handleSetReview = async () => {
    try {
      setError(null);
      const url =
        'https://memowrite.app.n8n.cloud/webhook/73453ac2-fd3b-40e3-82ed-996fc3e96a7e';
      const dataToSend = {
        email: selectedUser?.email,
        language: selectedUser?.language,
      };

      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(dataToSend),
      });
    } catch (error) {
      setError('Error submitting data. Please try again.');
      console.error('Error:', error);
    } finally {
      handleSetStatus('review_book');
    }
  };

  const selectChapterBubbleColor = (text, verified, qId, email) => {
    if (
      startChapter <= qId &&
      endChapter >= qId &&
      selectedUser?.email === email
    ) {
      return 'blue';
    }
    if (verified) {
      return 'green';
    }
    if (text === 'placeholder') {
      return 'grey';
    }
    if ((text || text === SKIP_ANSWER_VALUE) && text !== SKIPPED_ANSWER_VALUE) {
      return 'orange';
    }
    return '#e0e0e0';
  };

  const renderChaptersChart = (user) => {
    const placeholderData =
      user?.email === selectedUser?.email
        ? userQuestionsWithAnswers.map((i) => ({
            text: i?.answers?.length > 0 ? 'placeholder' : '',
          }))
        : Array(50).fill({ text: '' });
    const chartData = user?.book_qa_data;
    const mergedData = placeholderData?.map((item, idx) => ({
      ...item,
      ...chartData?.[idx + 1],
      questionId: idx + 1,
    }));

    return (
      <div
        style={{
          width: 140,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 4,
        }}
      >
        {mergedData
          ?.sort((a, b) => a?.questionId - b?.questionId)
          ?.map((i) => (
            <div
              key={i?.questionId}
              onClick={() => {
                setChapterStart(i?.questionId);
                setChapterEnd(i?.questionId);
              }}
              style={{
                width: 10,
                height: 10,
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: selectChapterBubbleColor(
                  i?.text,
                  i?.verified,
                  i?.questionId,
                  user?.email
                ),
              }}
            ></div>
          ))}
      </div>
    );
  };

  const RemoveButton = ({ status }) => (
    <Button
      size="sm"
      variant="danger"
      onClick={() => handleRemoveStatusClick(status)}
      style={{
        backgroundColor: '#EE632B',
        border: 'none',
        fontSize: 12,
        marginTop: 4,
      }}
    >
      Remove
    </Button>
  );

  return (
    <>
      <h4 style={{ marginTop: 20 }}>Users</h4>
      <Row className="mb-3">
        <Col xs={12} sm={4}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="User email"
              onChange={(e) => {
                setSearchUser(e.target.value);
              }}
            />
            <Button
              variant="dark"
              onClick={() => {
                setFilter(null);
                applyFilter(where('email', '==', searchUser));
              }}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
        <Col className="pt-1">
          <span style={{ marginRight: 16 }}>Filters:</span>
          {filtersData.map(({ label, value }) => (
            <Button
              key={value}
              size="sm"
              variant={filter === value ? 'dark' : 'outline-dark'}
              onClick={() => {
                setFilter(value);
                applyFilter(orderBy(value, 'desc'));
              }}
              style={{ marginRight: 8 }}
            >
              {label}
            </Button>
          ))}
        </Col>
      </Row>
      {dataLoading ? (
        <Col className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Col>
      ) : users?.length > 0 ? (
        <Table className="users-table" style={{ fontSize: 14 }}>
          <thead>
            <tr>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              ></th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>User</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Activity</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Platform</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Generate</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Review</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Ready for print</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Print</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Ship</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Chapters</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: 'white',
                  borderRightWidth: '1px',
                  backgroundColor: '#112423',
                }}
              >
                <span style={{ fontSize: 12 }}>Action</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => {
              return (
                <tr key={user?.uid}>
                  <td className="pt-3 bg-body-tertiary">
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        borderWidth: 3,
                        borderStyle: 'solid',
                        borderColor:
                          selectedUser?.email === user?.email
                            ? 'green'
                            : 'silver',
                        backgroundColor:
                          selectedUser?.email === user?.email
                            ? 'green'
                            : 'transparent',
                        cursor: 'pointer',
                        position: 'relative',
                        top: '-3px',
                      }}
                      onClick={() => setSelectedUserUid(user.uid)}
                    ></div>
                  </td>
                  <td className="pt-3 pb-3 bg-body-tertiary">
                    <div style={{ fontSize: 12, fontWeight: 600 }}>
                      {user?.email}
                    </div>
                    {user?.ip && (
                      <div style={{ fontSize: 12, marginTop: 8 }}>
                        <div>IP address:</div>
                        <div style={{ fontWeight: 600 }}>{user?.ip}</div>
                      </div>
                    )}
                    {user?.order_ids && (
                      <div style={{ fontSize: 12, marginTop: 8 }}>
                        <div>Order IDs:</div>
                        <div style={{ fontWeight: 600 }}>{user?.order_ids}</div>
                      </div>
                    )}
                  </td>
                  <td className="pt-3 pb-3 bg-body-tertiary">
                    {user?.createdAtDate && (
                      <div style={{ fontSize: 12 }}>
                        <div>Created at:</div>
                        <div style={{ fontWeight: 600 }}>
                          {user?.createdAtDate}
                        </div>
                      </div>
                    )}
                    {user?.lastVisitDate && (
                      <div style={{ fontSize: 12, marginTop: 8 }}>
                        <div>Last visit:</div>
                        <div style={{ fontWeight: 600 }}>
                          {user?.lastVisitDate}
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="pt-3 pb-3 bg-body-tertiary">
                    <span style={{ fontSize: 12 }}>
                      {user?.platform
                        ? user?.platform
                        : user?.name
                          ? 'app'
                          : 'email'}
                    </span>
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {selectedUser?.email === user?.email &&
                    !user?.generateBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => handleSetStatus('generate_book')}
                        style={{ fontSize: 12 }}
                      >
                        Set generate
                      </Button>
                    ) : (
                      <div
                        style={{
                          fontSize: 12,
                          textAlign: user?.generateBookDate ? 'left' : 'center',
                        }}
                      >
                        {user?.generateBookDate || '-'}
                      </div>
                    )}
                    {selectedUser?.email === user?.email &&
                      user?.generateBookDate && (
                        <RemoveButton status="generate_book" />
                      )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {selectedUser?.email === user?.email &&
                    !user?.review_book ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={handleSetReview}
                        style={{ fontSize: 12 }}
                      >
                        Set review
                      </Button>
                    ) : (
                      <div
                        style={{
                          fontSize: 12,
                          textAlign: user?.reviewBookDate ? 'left' : 'center',
                        }}
                      >
                        {user?.reviewBookDate || '-'}
                      </div>
                    )}
                    {selectedUser?.email === user?.email &&
                      user?.review_book && (
                        <RemoveButton status="review_book" />
                      )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {selectedUser?.email === user?.email &&
                    !user?.readyForPrintBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => handleSetStatus('ready_for_print_book')}
                        style={{ fontSize: 12 }}
                      >
                        Set ready for print
                      </Button>
                    ) : (
                      <div
                        style={{
                          fontSize: 12,
                          textAlign: user?.readyForPrintBookDate
                            ? 'left'
                            : 'center',
                        }}
                      >
                        {user?.readyForPrintBookDate || '-'}
                      </div>
                    )}
                    {selectedUser?.email === user?.email &&
                      user?.readyForPrintBookDate && (
                        <RemoveButton status="ready_for_print_book" />
                      )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {selectedUser?.email === user?.email &&
                    !user?.printBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => setPrintModal(true)}
                        style={{ fontSize: 12 }}
                      >
                        Set print
                      </Button>
                    ) : (
                      <>
                        <div
                          style={{
                            fontSize: 12,
                            textAlign: user?.printBookDate ? 'left' : 'center',
                          }}
                        >
                          {user?.printBookDate || '-'}
                        </div>
                        {user?.print_job_order_id && (
                          <div style={{ fontSize: 12, marginTop: 8 }}>
                            <div>Print job ID:</div>
                            <div style={{ fontWeight: 600 }}>
                              {user?.print_job_order_id?.id}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {selectedUser?.email === user?.email &&
                      user?.printBookDate && (
                        <RemoveButton status="print_book" />
                      )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {selectedUser?.email === user?.email &&
                    !user?.shipBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => setShipModal(true)}
                        style={{ fontSize: 12 }}
                      >
                        Set ship
                      </Button>
                    ) : (
                      <div
                        style={{
                          fontSize: 12,
                          textAlign: user?.shipBookDate ? 'left' : 'center',
                        }}
                      >
                        {user?.shipBookDate || '-'}
                      </div>
                    )}
                    {selectedUser?.email === user?.email &&
                      user?.shipBookDate && <RemoveButton status="ship_book" />}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {renderChaptersChart(user)}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {selectedUser?.email === user?.email ? (
                      emailRepliesLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 8,
                            }}
                          >
                            <Button
                              size="sm"
                              variant="dark"
                              onClick={generateChapters}
                              disabled={loading}
                              style={{ fontSize: 12 }}
                            >
                              {loading ? 'Generating...' : 'Generate chapters'}
                            </Button>
                            <input
                              type="number"
                              value={startChapter}
                              onChange={(e) =>
                                setChapterStart(e?.target?.value)
                              }
                              style={{ width: 40, marginLeft: 4 }}
                              min={1}
                            />
                            <input
                              type="number"
                              value={endChapter}
                              onChange={(e) => setChapterEnd(e?.target?.value)}
                              style={{ width: 40, marginLeft: 4 }}
                              max={50}
                            />
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                size="sm"
                                variant="outline-dark"
                                style={{ marginLeft: 4 }}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  style={{
                                    color:
                                      model === 'gpt-3.5-turbo'
                                        ? 'black'
                                        : 'grey',
                                  }}
                                  onClick={() => setModel('gpt-3.5-turbo')}
                                >
                                  gpt-3.5-turbo
                                </Dropdown.Item>
                                <Dropdown.Item
                                  style={{
                                    color: model === 'gpt-4' ? 'black' : 'grey',
                                  }}
                                  onClick={() => setModel('gpt-4')}
                                >
                                  gpt-4
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 8,
                            }}
                          >
                            <Button
                              size="sm"
                              variant="dark"
                              onClick={() => {
                                constructBookData();
                                setReviewModal(true);
                              }}
                              style={{ fontSize: 12 }}
                            >
                              Review book
                            </Button>
                            <Button
                              size="sm"
                              style={{ marginLeft: 4, fontSize: 12 }}
                              variant="danger"
                              onClick={() => {
                                setEditModal(true);
                              }}
                            >
                              Edit book
                            </Button>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 8,
                            }}
                          >
                            <Button
                              size="sm"
                              variant="outline-dark"
                              onClick={() => {
                                setClientInfoModal(true);
                              }}
                              style={{ fontSize: 12 }}
                            >
                              View client info
                            </Button>
                            <Button
                              size="sm"
                              style={{ marginLeft: 4, fontSize: 12 }}
                              variant="primary"
                              onClick={() => setSendToPrintModal(true)}
                            >
                              Send to print
                            </Button>
                          </div>
                          <div>
                            <Button
                              size="sm"
                              style={{ marginLeft: 4, fontSize: 12 }}
                              variant="danger"
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete user
                            </Button>
                          </div>
                        </>
                      )
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FaSearch size={20} style={{ marginRight: 4 }} />
          <span>Use search or filters</span>
        </div>
      )}
      {selectedUser?.uid && (
        <>
          <ReviewModal
            visible={reviewModal}
            onClose={() => setReviewModal(false)}
            onSave={handleSaveGeneratedAnswers}
            bookData={bookData}
            user={selectedUser}
          />
          <ClientInfoModal
            visible={clientInfoModal}
            onClose={() => {
              setClientInfoModal(false);
            }}
            clientInfo={selectedUser}
          />
          <ShipModal
            visible={shipModal}
            onClose={() => {
              setShipModal(false);
            }}
            uid={selectedUser?.uid}
            email={selectedUser?.email}
            language={selectedUser?.language}
          />
          <PrintModal
            visible={printModal}
            onClose={() => {
              setPrintModal(false);
            }}
            uid={selectedUser?.uid}
            email={selectedUser?.email}
            language={selectedUser?.language}
          />
          <BookReviewModal
            user={activeUser}
            visible={editModal}
            onClose={() => setEditModal(false)}
            isAdmin={true}
          />
          <SendToPrintModal
            visible={sendToPrintModal}
            onClose={() => setSendToPrintModal(false)}
            userData={selectedUser}
          />
        </>
      )}
      <ConfirmModal
        visible={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title={t('dashboardScreen.areYouSure')}
        text={t('dashboardScreen.youWillRemoveStatus')}
        icon={<IoWarning size={26} style={{ marginRight: 4 }} color="orange" />}
        onConfirm={removeSelectedStatus}
      />
      <ConfirmModal
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
        title={t('dashboardScreen.areYouSure')}
        text={t('dashboardScreen.youWillDeleteUser')}
        icon={<IoWarning size={26} style={{ marginRight: 4 }} color="orange" />}
        onConfirm={deleteUser}
      />
      {error && <ErrorToast errorMessage={error} />}
    </>
  );
};

export default UsersTable;
