import Progress from '../components/Progress';
import MainActivity from '../components/MainActivity';
import AllAnswers from '../components/AllAnswers';
import { useUserData } from '../hooks/useUserData';
import { useEffect } from 'react';
import { auth, updateUser } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function DashboardPage() {
  const { loading, userQuestionsWithAnswers } = useUserData();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;

    (async () => {
      await updateUser({
        uid: user?.uid,
        data: { last_visit_date: Math.round(Date.now() / 1000) },
        email: user?.email,
      });
    })();
  }, [loading, user]);

  return (
    <div className="mt-4">
      <Progress userQuestions={userQuestionsWithAnswers} />
      <MainActivity userQuestions={userQuestionsWithAnswers} />
      <div className="divider"></div>
      <AllAnswers
        isLoading={loading}
        questionsWithAnswers={userQuestionsWithAnswers}
      />
    </div>
  );
}

export default DashboardPage;
